import React from 'react'
import Prism from 'prismjs'

import Blogpost from '../../components/blogpost'

const New = () => <Code>new</Code>

class Code extends React.Component {
  componentDidMount() {
    Prism.highlightAll();
  }
  render() {
    const {children} = this.props;
    return (
      <code className={"language-js"} style={{fontSize: "inherit", backgroundColor: "inherit"}}>
        {children}
      </code>
    );
  }
}

class Codeblock extends React.Component {
  componentDidMount() {
    Prism.highlightAll();
  }
  render() {
    const {children} = this.props;
    return (<pre>
      <code className={"language-js"}>
        {children}
      </code>
    </pre>);
  }
}

const IndexPage = props => (
  <Blogpost
    disqusTitle="The Tyranny of the new keyword (I)"
    disqusIdentifier="asdf"
    disqusUrl={`http://dssti.com/${props.location.pathname}`}
    metaDescription="The new keyword does more harm than good because it affects how developers think and approach code"
    metaKeywords="new, keyword, programming, language, class, object, java, c#, typescript, python, haskell"
    title="The Tyranny of the new keyword (I)"
  >
    <h1>
      The Tyranny of the <New /> keyword (I)
    </h1>

    <p>
      Every modern programming language has a way to construct new values,
      because every minimally complex program is designed to process basic
      values into more complex results. The usual operation to summon creating a
      new value is the <New /> keyword, at least in Java-like languages, such as
      Java, C++, C#, TypeScript and others.
    </p>

    <p>
      But I think this <New /> keyword is based on wrong assumptions, and has
      done significantly more harm than good. Allow me to explain my points:
    </p>

    <h2>
      What does <New /> do under the hood?
    </h2>

    <p>
      <New /> is a keyword to instantiate a class into an object. It receives as
      parameters the class to instantiate, and the parameters to the
      constructor. A bit of digging under the hood reveals that <New /> works by
      doing:
    </p>

    <ul>
      <li>
        Allocate enough memory to hold the object. A call to <Code>malloc</Code>
        , <Code>jealloc</Code> or whatever other memory allocator (even implicit
        in a GC environment) happens.
      </li>
      <li>
        Initialize the memory to default values. Local variables are initialized
        to <Code>null</Code> or zeroes. The vtable is initialized with pointers
        to specific methods depending on the specific class instantiated.
      </li>
      <li>
        Summon the constructor with the specified parameters on a pointer to the
        object being created. The constructor does whatever needs to construct
        (user defined).
      </li>
      <li>Return a pointer to the newly created object.</li>
    </ul>

    <p>
      Well, none of these steps actually require special support by the
      programming language. The C guys would just do the four steps in
      succession and get over it. But having nicer syntax helps, doesn't it?
    </p>

    <h2>
      The results of having the <New /> keyword
    </h2>

    <p>
      <New /> is used to instantiate a class. Which means it lives somewhere
      between compile-time and run-time. Depending on the programming language,
      if types are erased during compilation, the class dissappears. If types
      are kept during compilation, the class is likely to live in some kind of
      limbo, not being a class anymore but something different. But this is not
      the main effect. The main effects happens on the mind of the programmer.
    </p>

    <h3>The first short-circuit</h3>

    <ul>
      <li>
        The way to instantiate a class is by using the <New /> keyword.
      </li>
      <li>
        Therefore I have to use the <New /> keyword to bring new objects to
        live.
      </li>
    </ul>

    <p>
      This is the first short circuit that happens on the mind of the
      programmer. It's effects are insidious, and will be multiplied later when
      we add more short-circuits on top of it. Bear in mind that every OO
      programming language that claims the previous, in fact, is violating it
      for many cases. After all, you don't do:
    </p>

    <ul>
      <li>
        <Code>var mynumber = new 3;</Code> - 3 is not the name of a class, so
        you can't really put it here.
      </li>
      <li>
        <Code>var mynumber = new Number(3);</Code> - Good, Number is a class,
        but how do you get a 3 to put it as a parameter?
      </li>
    </ul>

    <p>
      As soon as the programming language designer accepts this transgression,
      he happily throws in more transgressions. Here comes some C# where we
      create a new Tuple without invoking <New />:
    </p>

    <ul>
      <li>
        <Code>{`var mytuple = Tuple.Create<int, string>(3, "asdf");`}</Code>
      </li>
    </ul>

    <p>
      Obviously, neither of the parameters have been created with <New /> as
      well. Why bother at this point?
    </p>

    <h3>The second short-circuit</h3>

    <ul>
      <li>
        The way to call constructors in objects is by using the <New /> keyword
        so indirectly the constructor is called.
      </li>
      <li>
        Therefore, I need special effort to initialize an object, as I need to
        somehow call <New /> on it.
      </li>
    </ul>

    <p>
      So initialization of an object is something that can be done only as the
      result of <New />. Which means we have a single chance at initializing the
      object properly, and we have to use that chance properly. Soon, all kinds
      of design patterns arise to be able to use this incredibly valuable single
      opportunity, such as the Builder design pattern.
    </p>

    <h3>The third short-circuit</h3>

    <ul>
      <li>
        I can't swap a class name in an instantiation, because the class name is
        constant and exists at compile-time only.
      </li>
      <li>
        Therefore I have to construct something that allows me to swap the class
        name at runtime.
      </li>
    </ul>

    <p>
      You won't find a programming language with the <New /> keyword that
      accepts the obvious code below:
    </p>
    <Codeblock language="java">
      {`
var myclass;
switch(someSetting) {
  case development:
    myclass = MyClassForDevelopment;
    break;
  case testing:
    myclass = MyClassMockForTesting;
    break;
  case production:
    myclass = MyClassUsingTheProductionDatabase;
    break;
}

var myobject = new myclass();
`}
    </Codeblock>

    <p>
      The reason for rejecting the code above is that classes are not something
      that can be assigned to a variable, and the only way to call <New /> is by
      supplying a class, which means you can't swap a class with another at
      runtime, even though the OO-is-classes guys claim that you should be able
      to replace a class with a subclass.
    </p>

    <p>
      With this inability to switch classes easily, the programmer evolves to
      Your Highness the Architect, and invents the Abstract Factory.
    </p>

    <h3>The fourth short-circuit</h3>

    <ul>
      <li>
        There is a single constructor (or maybe some constructors if you are in
        C#) and it receives a fixed list of parameters.
      </li>
      <li>
        (Optional) I shouldn't create many constructors because then it will be
        hard to figure out which one to use.
      </li>
      <li>
        Therefore I need a helper thinguie to help me instantiate my complex
        class.
      </li>
    </ul>

    <p>
      And now we have another reason for the Builder pattern. We have a complex
      class (which is a problem on its own), and we fix that complexity by
      creating another class so we can hide the complexity of constructing my
      complex object under a blanket of more complexity intended to reduce the
      complexity of constructing a complex object with complex parameters.
      Before it, we had a problem. Now we have two. What a great improvement.
    </p>

    <h2>Conclusion of the first part</h2>

    <p>
      This is only the tip of the iceberg. On the next chapters we will explore
      in deep how this minor detail has shaped the mind of a whole generation of
      programmers in order to make them significantly less strong than they
      could be.
    </p>
  </Blogpost>
)

const exports = {}
exports.frontmatter = {
  title: `The Tyranny of the 'new' keyword (I)`,
  date: '2018-11-27',
  series: "The Tyranny of the 'new' keyword",
}

export default IndexPage
